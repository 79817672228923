import { Button, Card, CardActions, CardContent } from '@mui/material'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FallbackProps } from '@thriveglobal/thrive-web-errors'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    defaultErrorDescription: {
        defaultMessage: 'Unable to load.',
        description: 'Unable to load component'
    },
    defaultUserMessage: {
        defaultMessage:
            'Looks like we can’t load the feature you’re looking for.',
        description:
            "Explanation to user that the feature they're looking for cannot be found."
    },
    retryButtonLabel: {
        defaultMessage: 'Retry',
        description:
            'Button text instructing user to retry loading the feature.'
    },
    cantBeLoaded: {
        defaultMessage: 'Feature is currently unavailable.',
        description:
            'Button text instructing user that feature cannot be loaded.'
    }
})

const ComponentFallback = ({
    error,
    resetErrorBoundary,
    errorCount
}: FallbackProps) => {
    const { formatMessage } = useIntl()

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '100%'
            }}
        >
            <CardContent sx={{ width: '100%', textAlign: 'center' }}>
                <CoreTypography component="h1" variant="h3">
                    {formatMessage(messages.cantBeLoaded)}
                </CoreTypography>
                <CoreTypography variant="body1">
                    {error?.message ||
                        formatMessage(messages.defaultUserMessage)}
                </CoreTypography>
            </CardContent>
            {errorCount < 3 && (
                <CardActions>
                    <Button variant="contained" onClick={resetErrorBoundary}>
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(messages.retryButtonLabel)}
                        </CoreTypography>
                    </Button>
                </CardActions>
            )}
        </Card>
    )
}
export default React.memo(ComponentFallback)
