import {
    ErrorBoundary,
    type ErrorBoundaryProps
} from '@thriveglobal/thrive-web-errors'
import React from 'react'
import ComponentFallback from '../ComponentFallback/ComponentFallback'
import PageFallback from '../PageFallback/PageFallback'

export const withErrorBoundary = <P extends object>(
    boundaryName: ErrorBoundaryProps['boundaryName'],
    boundaryType: ErrorBoundaryProps['boundaryType'],
    Component: React.FunctionComponent<P>,
    fallbackComponent?: ErrorBoundaryProps['fallbackComponent']
) => {
    return (props: P) => (
        <ErrorBoundary
            boundaryName={boundaryName}
            boundaryType={boundaryType}
            packageName={process.env.APP_NAME}
            packageOwner={process.env.PROJECT_OWNER}
            packageVersion={process.env.APP_VERSION}
            fallbackComponent={
                fallbackComponent ?? boundaryType === 'Page'
                    ? PageFallback
                    : ComponentFallback
            }
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
