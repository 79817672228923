import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { FullAppFallback } from '@thriveglobal/thrive-web-errors'
import ErrorBoundaryWrapper from './components/fallbacks/ErrorBoundaryWrapper'
import { captureException } from '@thriveglobal/thrive-web-core'

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        return (
            <ErrorBoundaryWrapper
                boundaryName="DashboardAppContainer"
                boundaryType={'MicroFrontend'}
                errorOnMount={err}
                fallbackComponent={FullAppFallback}
            />
        )
    }
})

export const { bootstrap, mount, unmount } = lifecycles
