import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import {
    LogoutCallback,
    LoginCallback,
    ProtectedRoute
} from '@thriveglobal/thrive-web-auth-core'

const DefaultPage = lazy(() => import('../pages/Default'))

enum ROUTES {
    LOGIN_CALLBACK = '/login/callback',
    LOGOUT = '/logout'
}
export default function Router(props) {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense fallback={<></>}>
                    <Switch>
                        <Route
                            path={ROUTES.LOGIN_CALLBACK}
                            component={LoginCallback}
                        />
                        <Route
                            path={ROUTES.LOGOUT}
                            component={LogoutCallback}
                        />
                        <ProtectedRoute component={DefaultPage} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}
